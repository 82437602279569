import React from 'react'
import { colors } from '@dqp/common/styles/utilities'
import Wave from '@dqp/common/components/Wave/Wave'
import PropTypes from 'prop-types'
import Hero from './Hero'
import ArticlesGrid from './ArticlesGrid'

function Advice({ articlesForGridSection, articlesForHeroSection }) {
  return (
    <>
      <Hero articles={articlesForHeroSection} />
      <Wave bg={colors.purple} fill={colors.white} />
      <div id='articles' />
      <ArticlesGrid articles={articlesForGridSection} />
    </>
  )
}
Advice.propTypes = {
  articlesForGridSection: PropTypes.array.isRequired,
  articlesForHeroSection: PropTypes.array.isRequired,
}
export default Advice
