import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import {
  colors,
  spacer,
  breakpoints,
  mixins,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Link from '@dqp/common/components/Link/Link'
import Button from '@dqp/common/components/Button/Button'
import ArticleCard from './ArticleCard'
import Image from '#components/Image'

const styles = {
  container: css`
    background-color: ${colors.purple};
    ${breakpoints({
      paddingTop: [40, 40, 120, 60, 60],
      paddingBottom: [80, 80, 120, 120, 120],
    })};
  `,
  li: css`
    border-bottom: 1px solid #a4a4a4;
    padding: 30px 0;
    :first-of-type {
      padding-top: 0;
    }
    :last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
    :hover {
      opacity: 0.7;
    }
    ${mixins.transition()}
  `,
  pen: css`
    transform: rotateY(180deg) rotate(15deg);
    ${breakpoints({
      bottom: [null, null, -375, -500, -500],
      left: [null, null, -135, -250, -250],
    })};
  `,
  confetti: css`
    transform: rotate(-40deg);
    ${breakpoints({
      bottom: [0, 0, -185, -230, -230],
      right: [0, 0, -175, -250, -250],
    })};
  `,
  card: css`
    :hover {
      button {
        background-color: ${colors.btnBlackHover};
      }
    }
    ${mixins.transition()}
  `,
}
function Hero({ articles }) {
  return (
    <div css={styles.container}>
      <div className='container relative'>
        <Title
          as='h1'
          size='xxxLarge'
          weight='bold'
          color={colors.white}
          css={spacer.mrB30}
          className='text-left'
        >
          Latest Articles
        </Title>
        <div className='row'>
          <div className='col-lg-7 pb-lg-0' css={spacer.padB30}>
            <ArticleCard
              {...articles[0]}
              textColor={colors.white}
              imgHeight='400'
              css={styles.card}
            />
          </div>

          <div className='offset-lg-1 col-lg-4'>
            <ul>
              {articles.slice(1).map(article => (
                <li key={article.slug} css={styles.li}>
                  <Link className='d-block' to={`/${article.slug}`}>
                    <Title css={spacer.mrB5} size='large'>
                      {article.title}
                    </Title>
                    <Text css={spacer.mrB20} size='small'>
                      {article.excerpt}
                    </Text>
                    <Text size='small' color={colors.black}>
                      <Button underlineColor={colors.black}>
                        Read more
                      </Button>
                    </Text>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Image
          css={styles.pen}
          className='d-none d-md-block'
          alt='pen'
          image='home/pen'
          sizes={{
            lg: {
              width: 369,
              height: 408,
            },
            md: {
              width: 244,
              height: 270,
            },
          }}
        />
        <Image
          lazy
          css={styles.confetti}
          className='d-none d-md-block'
          alt='confetti'
          image='about/confetti'
          sizes={{
            lg: {
              width: 238,
            },
            md: {
              width: 180,
            },
          }}
        />
      </div>
    </div>
  )
}

Hero.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default Hero
