/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Link from '@dqp/common/components/Link/Link'
import { LeftArrow, RightArrow } from '@dqp/common/components/icons'
import Text from '@dqp/common/components/Text/Text'
import { css } from '@emotion/core'
import Wave from '@dqp/common/components/Wave/Wave'
import PublicLayout from '#components/PublicLayout'
import SEO from '#components/SEO'
import Advice from '#containers/Advice/Advice'
import routes from '#globals/routes'

const styles = {
  arrows: doesPageExist => css`
    opacity: ${doesPageExist ? 1 : 0};

    pointer-events: ${doesPageExist ? 'auto' : 'none'};
    cursor: ${doesPageExist ? 'pointer' : 'not-allowed'};
  `,

  pageNumbers: css`
    ${spacer.mrR30};
    :first-of-type {
      ${spacer.mrL30};
    }
  `,
}

const activeStyles = {
  style: {
    color: colors.black,
  },
}
const AdvicePage = ({ pageContext }) => {
  const {
    articlesForHeroSection,
    articlesForGridSection,
    paginatorInfo,
  } = pageContext
  const {
    totalPages,
    nextPageUrl,
    previousPageUrl,
    hasMorePages,
    currentPage,
  } = paginatorInfo
  const paginationLinksMarkup = (
    <div
      className='container d-flex align-items-center justify-content-center'
      css={spacer.padT60}
    >
      <Link
        to={`${previousPageUrl}?#articles`}
        css={styles.arrows(currentPage > 1)}
      >
        <LeftArrow />
      </Link>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map(v => (
        <Text
          size='large'
          weight='bold'
          color={colors.grey}
          key={v}
          css={styles.pageNumbers}
        >
          <Link
            to={`${routes.advice}${v === 1 ? '' : v}/?#articles`}
            getProps={({ location, href }) =>
              location.pathname === href.replace('?#articles', '')
                ? activeStyles
                : null
            }
          >
            {v}
          </Link>
        </Text>
      ))}
      <Link
        to={`${nextPageUrl}?#articles`}
        css={styles.arrows(hasMorePages)}
      >
        <RightArrow />
      </Link>
    </div>
  )

  return (
    <PublicLayout headerColor={colors.purple}>
      <SEO
        title='Advice'
        meta={[{ name: 'theme-color', content: colors.purple }]}
      />
      <Advice
        articlesForGridSection={articlesForGridSection}
        articlesForHeroSection={articlesForHeroSection}
      />
      {totalPages > 1 && paginationLinksMarkup}
      <Wave fill={colors.white} bg={colors.white} />
    </PublicLayout>
  )
}

export default AdvicePage
